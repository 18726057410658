import 'iconify-icon';
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'flowbite';
import Swal from 'sweetalert2';
import $ from 'jquery';

// import 'flowbite';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
window.Alpine = Alpine;
Alpine.plugin(collapse)
Alpine.start();

// import lazyload from 'lazyload';
// $(function($) {
//   $("img.lazy").lazyload();
// });
// $(function(){
//   $("img").lazyload();
// });

const homeSwiper = new Swiper('.home-swiper', {
  modules: [Navigation, Pagination],
  loop: true,
  navigation: {
    nextEl: '.home-swiper-next',
    prevEl: '.home-swiper-prev',
  },
});

const homeOurServices = new Swiper('.home-our-services', {
  modules: [Navigation, Pagination],
  loop: true,
  spaceBetween:10,
  breakpoints:{
    0:{
        slidesPerView:1
    },
    480:{
      slidesPerView:2
    },
    768:{
      slidesPerView:3
    },
    992:{
      slidesPerView:4
    }
  }
});

$('form#news-form').submit(function(){
  $.post($(this).attr('action'), $(this).serialize())
    .done(function(res){
      Swal.fire({
        icon: 'success',
        title: 'Tebrikler!',
        text: res.message,
      })
    })
    .fail(function(xhr, status, error){
      var obj = JSON.parse(xhr.responseText);
      obj = obj.errors;
      var errors = '';
      $.each(obj, function(key,val){
        errors += ' - '+val+"\n";
      });
      Swal.fire({
        icon: 'error',
        title: 'Hata!',
        text: errors,
      })
    });
  return false;
});

AOS.init();


// document.addEventListener("DOMContentLoaded", function() {
//   var lazyloadImages = document.querySelectorAll("img.lazy");
//   var lazyloadThrottleTimeout;

//   function lazyload () {
//     if(lazyloadThrottleTimeout) {
//       clearTimeout(lazyloadThrottleTimeout);
//     }

//     lazyloadThrottleTimeout = setTimeout(function() {
//         var scrollTop = window.pageYOffset;
//         lazyloadImages.forEach(function(img) {
//             if(img.offsetTop < (window.innerHeight + scrollTop)) {
//               img.src = img.dataset.src;
//               img.classList.remove('lazy');
//             }
//         });
//         if(lazyloadImages.length == 0) {
//           document.removeEventListener("scroll", lazyload);
//           window.removeEventListener("resize", lazyload);
//           window.removeEventListener("orientationChange", lazyload);
//         }
//     }, 20);
//   }

//   document.addEventListener("scroll", lazyload);
//   window.addEventListener("resize", lazyload);
//   window.addEventListener("orientationChange", lazyload);
// });
